<template>
  <!-- <div id="login">
    <a-form class="loginForm" :model="loginForm" v-bind="layout" :rules="loginFormRules">
      <div style="text-align: center;font-size:30px;margin-bottom:30px;color: #505458;">
        生物特征资源后台管理系统
      </div>
      <a-form-item label="账户:" name="u_id" has-feedback>
        <a-input v-model:value="loginForm.u_id" autocomplete="off"></a-input>
      </a-form-item>
      <a-form-item label="密码:" name="u_pwd" has-feedback>
        <a-input v-model:value="loginForm.u_pwd" type="password" autocomplete="off" @keyup.enter="onSubmit"></a-input>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 9, offset: 8 }">
        <a-button type="primary" @click="onSubmit" block>
          登录
        </a-button>

      </a-form-item>
    </a-form>
  </div> -->
  <div id="login">
    <div class="loginForm">
      <div style="position: relative;">
        <img src="../assets/loginform_bg.png" alt="">
      </div>
      <div class="loginform_right">
        <div style="display:flex;align-items: center;justify-content: center;margin-top: 100px;">
          <img src="../assets/login_logo.png" alt="">
          <div style="margin-left: 10px;font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 24px;
              color: #333;">九喵学社</div>
        </div>
        <div style="display: flex;margin-top: 50px;flex-direction: column;">
          <a-form style="width: 400px;" :model="loginForm" :rules="loginFormRules">
            <a-form-item name="u_id">

              <a-input placeholder="请输入用户名" class="logininput" v-model:value="loginForm.u_id" />
              <div class="required"></div>


            </a-form-item>
            <a-form-item name="u_pwd">

              <a-input placeholder="请输入密码" class="logininput" v-model:value="loginForm.u_pwd" type="password"
                @keyup.enter="onSubmit" />
              <div class="required"></div>

            </a-form-item>
          </a-form>
        </div>
        <div class="loginbtn" @click="onSubmit">
          立即登录
        </div>
        <div style="margin-top: 123px;">
          西安索尔软件科技有限公司丨<a style="color: #333;" target="_blank" href="http://beian.miit.gov.cn" data-v-61fbe261="">陕ICP备16012461号-2</a>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import routes from '@/common/router';
  import { setLocalToken } from "@/common/token.js"
  import ddo from '@/common/ddo.js'//数据字典对象
  import { reactive } from "vue"
  import { message } from "ant-design-vue"
  import { useRouter } from "vue-router"
  import { myPost } from "@/common/request.js"
  import { useForm } from '@ant-design-vue/use';
  import { encode64 } from "@/common/security.js";
  const API = {
    loginUrl: '/xkcwebApi/user/xkcWebLogin.htm',
  };
  export default {
    setup() {
      const router = useRouter()
      const layout = reactive({
        labelCol: {
          span: 2,
          offset: 6
        },
        wrapperCol: {
          span: 9
        }
      })
      const loginForm = reactive({
        u_id: '',
        u_pwd: ''
      })
      const loginFormRules = reactive(
        {
          u_id: [{
            required: true,
            message: '请输入账户！',
            trigger: ['blur', 'change']
          }, {
            min: 0,
            max: 15,
            message: '账户必须应当为0~15个字符',
            trigger: 'blur'
          }],
          u_pwd: [{
            required: true,
            message: '请输入密码！',
            trigger: ['blur', 'change']
          }],

        }
      )
      const { validate } = useForm(loginForm, loginFormRules);
      const onSubmit = () => {
        const temp = {
          // type: 1,
          accountName: encode64(loginForm.u_id),
          password: encode64(loginForm.u_pwd),
        }
        validate()
          .then(() => {
            myPost(API.loginUrl, temp).then((res) => {
              // 设置token
              setLocalToken(res.data.token);
              // 设置用户信息
              ddo.setUserInfo(res.data)
              message.success('登录成功！');
              router.replace('/dashboard')
              // -------------------------------------------
        //       myPost('/api/admin/system/menu/findTreeMenu.htm', { id: 1 }).then(async (res) => {

        // ddo.setMenu(routes)
        //         // 存储重定向路由和菜单栏的默认选中与打开
        //         let temRedirect = {
        //           selectedKeys: [],
        //           openKeys: [],
        //           redirectUrl: ''
        //         }
        //         if (res.data && res.data[0]) {

        //           if (res.data[0].isChildren) {
        //             temRedirect.selectedKeys.push(res.data[0].children[0].t.id)
        //             temRedirect.openKeys.push(res.data[0].t.id)
        //             temRedirect.redirectUrl = res.data[0].children[0].t.url
        //           } else {
        //             temRedirect.selectedKeys.push(res.data[0].t.id)
        //             temRedirect.redirectUrl = res.data[0].t.url

        //           }
        //         }
        //         ddo.setRedirect(temRedirect)
        //         router.replace('/')
        //       })
              // router.replace('/')

            });

          })
          .catch(error => {
            console.log('error', error);
          });
      };
      return {
        layout,
        loginForm,
        loginFormRules,
        onSubmit

      }


    },


  }
</script>

<style scoped>
  #login {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/login_big_bg.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 100%;
  }

  #login .loginForm {
    display: flex;

    width: 940px;
    height: 600px;
  }

  .loginform_right {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 600px;
    border-radius: 0 30px 30px 0;
    background-color: white;
  }

  .logininput {
    box-sizing: border-box;
    padding-left: 28px;
    font-size: 20px;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    width: 400px;
    height: 70px;
    background: #F2F2F2;
    opacity: 1;
    border-radius: 6px;
  }

  .loginbtn {
    margin-top: 30px;
    text-align: center;
    width: 400px;
    line-height: 48px;
    background: #637CFE;
    opacity: 1;
    border-radius: 10px;
    font-size: 18px;
    color: #FFFFFF;
  }

  .required {
    width: 8px;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #637CFE;
    border-radius: 6px 0 0 6px;
  }
</style>